.dropdown {
    position: relative;
    position: fixed;
    top: 3%;
    right: 3%;
    z-index: 100;
  }
  
  .dropdown-button{
    display: flex;
    
    
  }

  .dropdown-button p{
    color: black;
    margin-left: 5px;
  }

  .dropdown-toggle {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0; 
    color: #333; 
    transition: color 0.2s ease;
    scale: 1.5;
  }

  .dropdown-toggle:hover {
    color:rgb(67, 20, 153);
  }
  
  
  .dropdown-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center ; 
    top: 100%;
    right: 10%;
    background-color: #fff;
    border-radius: 8px; 
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); 
    z-index: 10;
  }
  
  .dropdown-menu li {
    list-style: none;
    padding: 10px 20px 10px 0px;

  }
  
  .dropdown-item {
    text-decoration: none;
    color: #333;
    font-weight: 500;
    transition: color 0.2s ease; 
  }
  
  .dropdown-item:hover {
    color: #007bff; 
  }
  