.site-footer {
background-color: #26272b;
font-size: 15px;
line-height: 24px;
color: #737373;
box-shadow: 0 10px 20px rgba(0,0,0,0.5);
padding: 1rem 0;;
}

.containerabove{
    display: flex;
    justify-content: space-between;
}

.footerquick h6{
    text-wrap: nowrap;
}

.footerabout{
    flex: 1;
    margin-left: 9%;
}

.footercommodity{
    flex: 0.5;
    margin-left: 9%;
}
.footerquick{
    flex: 0.5;
    margin-left: 2rem;
}

.containerbelow{
    margin-left: 9%;
    margin-right: 9%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.site-footer hr {
border-top-color: #bbb;
opacity: 0.5;
}
.site-footer hr.small {
margin: 20px 0;
}
.site-footer h6 {
color: #fff;
font-size: 16px;
text-transform: uppercase;
margin-top: 5px;
letter-spacing: 2px;
}
.site-footer a {
color: #737373;
}
.site-footer a:hover {
color: #fff;
text-decoration: none;
}
.footer-links {
padding-left: 0;
list-style: none;
}
.footer-links li {
display: block;
}
.footer-links a {
color: #737373;
}
.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
color: #fff;
text-decoration: none;
}
.site-footer .social-icons {
text-align: right;
}
.site-footer .social-icons a {
width: 40px;
height: 40px;
line-height: 40px;
margin-left: 6px;
margin-right: 0;
border-radius: 100%;
background-color: #33353d;
}
.copyright-text {
margin: 0;
}

.email{
    margin-left: 9%;
}


@media (max-width: 1100px) {
.site-footer {
padding-bottom: 0;
}


.footerabout{
    margin-left: 1rem;
}
.site-footer .copyright-text,
.site-footer .social-icons {
text-align: center;
}
}
.social-icons {
padding-left: 0;
margin-bottom: 0;
list-style: none;
}
.social-icons li {
display: inline-block;
margin-bottom: 4px;
}
.social-icons a {
background-color: #eceeef;
color: #818a91;
font-size: 16px;
display: inline-block;
line-height: 44px;
width: 44px;
height: 44px;
text-align: center;
margin-right: 8px;
border-radius: 100%;
-webkit-transition: all 0.2s linear;
-o-transition: all 0.2s linear;
transition: all 0.2s linear;
}
.logo{
color:#eb6857;
}
.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
color: #fff;
background-color: #29aafe;
}
.social-icons a.facebook:hover {
background-color: #3b5998;
}
.social-icons a.twitter:hover {
background-color: #00aced;
}
.social-icons a.linkedin:hover {
background-color: #007bb6;
}


.popup {
    position: fixed;
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgba(0, 0, 0, 0.4); 
  }
  
  .popup-content {
    background-color:white;
    margin: 15% auto; 
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
    height: 55%;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .popup-content h3{
    color: black;
  }

  .popup-content p{
    text-align: center;
  }