.services{
    margin-top: 12rem;
}

.servicescontainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 6rem;
}

.serviceheader{
    font-size: 28px;
    font-weight: 800;
    letter-spacing: 3px;
    white-space: nowrap;
    color:rgb(36, 34, 34);
    margin-bottom: 5rem;
    margin-left: 9%;
    margin-right: 9%;
}

.serviceselement{
    display: flex;
    justify-content:center;
    align-items: center;
}

.leftservicecontainer{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 9%;
    margin-right: 9%;
}

.servicesdesc{
    margin-left: 9%;
    margin-right: 9%;
}

.leftservice1{
    font-weight: 400;
    font-family: roboto;
    font-size: 1.3rem;
    letter-spacing: 2px;
    line-height: 2rem;
    color: #271b12;
    margin-bottom: 3rem;
}

.leftservice2, .leftservice3{
  
    font-weight: 400;
    font-family: roboto;
    font-size: 1.3rem;
    letter-spacing: 2px;
    line-height: 1.9rem;
    color: #271b12;
    margin-bottom: 3rem;
}


.rightservicecontainer{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.rightservice1{
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 35px;
    font-weight: 800;
    letter-spacing: 3px;
    white-space: nowrap;
    color:rgb(36, 34, 34);
}

.rightservice2{
    font-family: "Lobster", sans-serif;
    font-size:45px ;
    font-weight: 100;
    font-style: normal;
    color: #ffa600;
    margin-top: 0;
    margin-bottom:0;
}

@media(max-width:1100px){

    .leftservicecontainer{
        flex-direction: column-reverse;
    }

    .leftservice1{
        margin-top: 6rem;
        margin-left: 9%;
        margin-right: 9%;
    }
    .leftservice1, .leftservice2, .leftservice3{
        text-align: center;
    }

    .rightservicecontainer{

        width: 100%;
        background-color: #857f71;

    }
    
}
