.commoditycontainer{
    width: 100%;
}

.commodityheader{
    text-align: center;
    font-size: 35px;
    font-weight: 800;
    letter-spacing: 3px;
    color:rgb(36, 34, 34);
    margin-bottom: 3rem;
}

.commoditydescription{
    text-align: center;
    font-weight: 400;
    font-size: 1.3rem;
    font-family: roboto;
    letter-spacing: 2px;
    line-height: 1.9rem;
    color: #ffa600;
    margin: 0 1rem ;
    margin: 0 5% 5rem 5%;
}