
.form{
    display: flex; 
    justify-content: center;
    align-items: center;
    height: 100vh; 
    width: 100%;
    background-color: #4CAF50;
  
}

.form-container{

    padding: 1rem;
    width: 300px;
    border: 5px solid white;
    border-radius: 8px;
    background-color: white;
    
}
  
  h3 {
    margin-top: 6%;
    text-align: center;
    margin-bottom: 20px;
    font-size: 30px;
    color: white;
  }
  
  .mb-3 label {
    display: block;
    margin-bottom: 5px;
  }
  
  .mb-3 input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 15px;
  }
  
  .btn-primary {
    background-color: #4CAF50; 
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    margin-top: 1rem;
  }
  
  .btn-primary:hover {
    background-color: #3e8e41; /* Darker green on hover */
  }
  
  .forgot-password {
    margin-top: 15px;
    font-size: 14px;
    color: black;
    width: 100%;
    text-align: center;
    font-size: 1rem;

  }
  
  .forgot-password a {
    color: black; 
    text-decoration: none;
  }
  
  .forgot-password a:hover {
    text-decoration: underline;
  }