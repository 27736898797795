.tiermembers{
    margin-bottom: 12rem;
}

.tiermembers h1{
    text-align: center;
    font-size: 30px;
    font-weight: 800;
    color:rgb(36, 34, 34);
    letter-spacing: 3px;
    margin-bottom: 0;
    white-space: nowrap;
}
.members{
    display: flex;
    justify-content: space-around;
    margin-left: 7%;
    margin-right: 7%;
}

.members img{
    height: 150px;
    width: auto;
}

.seperator{
    height:200px ;
    width: 10px;
    background-color: rgb(187, 172, 172);
}

.break{
    padding: 2px;
    /* background-color:#ffa600; */
    background-color:rgb(36, 34, 34);
    
    border: none;
    margin-bottom: 3rem;
    margin-top: 4rem ;
    width: 80%;
}

.tiercontainer p{
    text-align: center;
    font-weight: 400;
    font-size: 1.3rem;
    font-family: roboto;
    letter-spacing: 2px;
    line-height: 1.9rem;
    /* color: #ffa600; */

    color:rgb(36, 34, 34);

    font-weight: 100;
    font-style: normal;
    
    margin-bottom: 8rem;
    margin-left: 5%;
    margin-right: 5%;
}

@media(max-width:700px){
    .members{
        flex-direction: column;
        /* width: 50%; */
    }

    .members img{
        object-fit: contain;
    }
    .seperator{
        height: 10px;
        width: 80%;
        margin-left: 8%;
        margin-top: 4rem;
        margin-bottom: 2rem;
        
       
    }
}