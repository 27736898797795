.aboutus{
    width: 100%;
    margin-bottom: 10rem;
}

.aboutuscontainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.aboutusheader{
    font-size: 35px;
    font-weight: 800;
    letter-spacing: 3px;
    color:rgb(36, 34, 34);
    
    
}

.slogancontainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 9%;
}
.aboutusslogan1{
    text-align: center;
    font-weight: 400;
    font-size: 1.3rem;
    font-family: roboto;
    letter-spacing: 2px;
    line-height: 1.9rem;
    color: #ffa600;
    margin: 0 1rem ;
}

.aboutusslogan2{
    text-align: center;
    font-weight: 400;
    font-size: 1.3rem;
    font-family: roboto;
    letter-spacing: 2px;
    line-height: 1.9rem;
    color: #ffa600;
    margin: 0 1rem;
}

.paragraphsection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.aboutuspara1{
    text-align: center;
    font-weight: 400;
    font-family: roboto;
    font-size: 1.2rem;
    letter-spacing: 2px;
    line-height: 1.9rem;
    color: #271b12;
    margin: 2rem 9% 0 9%;
    
   
}

.aboutuspara2{
    text-align: center;
    font-weight: 400;
    font-family: roboto;
    font-size: 1.2rem;
    letter-spacing: 2px;
    line-height: 1.9rem;
    color: #271b12;
    margin: 2rem 9% 0 9%;

}