body {
  overflow: auto;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(62, 62, 62);
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

.logoimage{
  width: 230px;
  height: auto;
}

.row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 2rem 0; */
  margin-left: 0.5rem;

}

.navbar{
  margin-top: 6%;
  margin-left: 2%;
}

.topbar {
  display: flex;
  list-style-type: none;
  justify-content: center;
  padding: 0;
  margin-right: 2rem;

}

.topbar li{
  margin-right: 3rem;
  font-size: 15px;
  color: #271b12;
  cursor: pointer;
  font-weight:400;
  letter-spacing: 2.5px;
  transition: transform 0.3s ease;
}

.topbar li:hover{
  transform: scale(1.25);
}

.verticaldivider {
  height: 35px;
  width: 1px;
  background-color:  rgb(7, 73, 7); 

  margin: 0 20px;

}

.header{
  height: 92vh;
  margin-bottom: 0;

}


.header img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 98%;
  object-fit: cover;
  z-index: -1;
  filter: blur(5px);
}

.headertext{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  margin-top: 2rem;
}

.headertext3 {
  font-size: 30px;
  font-weight: 800;
  color:rgb(36, 34, 34);
  letter-spacing: 3px;
  white-space: nowrap;
  margin-bottom: 0;
}

.headertext1{
  margin-top: 0rem;
  font-size: 30px;
  font-weight: 800;
  color:rgb(36, 34, 34);
  letter-spacing: 3px;
  margin-bottom: 0;
  white-space: nowrap;

}

.headertext2{
  margin-top: 0;
  font-size: 40px;
  font-family: "Lobster", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #ffa600;
}

.menu-button{
  display: none;
}

.menu-container{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  overflow-y: scroll;

}

.menu-items{
  margin-top: 15rem;
}

.menu-items li{
  list-style: none;
  color: white;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: 4px;
  margin: 3rem 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.midsection{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 8rem;

}

.leftmidsection{
  flex: 0.9;
  margin-left: 8%;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.leftmidcontent1, .leftmidcontent2, .leftmidcontent3{
  margin-top: 0;
  margin-bottom: 0.6rem;
  font-size: 28px;
  font-weight: 800;
  letter-spacing: 3px;
  white-space: nowrap;
  color:rgb(36, 34, 34);

}
 .leftmidcontent4, .leftmidcontent5{
  font-family: "Lobster", sans-serif;
  font-size:40px ;
  font-weight: 100;
  font-style: normal;
  color: #ffa600;
  margin-top: 0;
  margin-bottom:0;
 }

.rightmidsection{
  flex: 1.1;
  margin-right: 7%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 400;
  font-family: roboto;
  font-size: 1.2rem;
  letter-spacing: 2px;
  line-height: 1.9rem;
  color: #271b12;
}

@media(max-width:1100px){
  .topbar{
    display: none;

  }

.verticaldivider{
  display: none;
}
  .logo{
    position: absolute;
    top: 3rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 150;
  }

  .logoimage{
    height: 4rem;
    width: auto;
  }

  .menu-button{
    position: fixed;
    top:1rem;
    right: 0.1rem;
    display: block;
    background: none;
    border: none;
    color:#c49102;
    margin-right: 1rem;
    cursor: pointer;
    z-index: 101;
  }
  .midsection{
    flex-direction: column;
  }
  .leftmidsection{
    text-align: center;
    margin-bottom: 1rem;
  }
  .rightmidsection{
    text-align: center;
  }
 
}
