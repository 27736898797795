.card{
    padding: 0;
    position: relative;
    height:370px;
    width: 50%;
    overflow: hidden;
}


.card:hover .carddesc{
    transform: translateY(-50%);
    width: 100%;
    height: 50%;
    background-color: #58431b;

    opacity: 0.9;
}

.cardcontainer  img{
    width: 100%;
    height: 100%;
    object-fit:cover;
}

.cardtitle{
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 4px;
    z-index: 100;
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    color: white;
    background-color: rgba(71, 66, 66, 0.7);
    padding: 18px;
    margin: 0;
    transition: all 0.7s ease;
}

.carddesc {
    position: absolute;
    text-align: center;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    margin: 0;
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity 0.7s ease, transform 0.7s ease;
}

@media (max-width: 1100px) {
    .card{
        width: 100%;
        height: 280px;
        /* margin: 5% 10%; */
        margin-bottom: 1rem;
    }


}